/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */

import React from "react";
import Layout from "~components/Layout";
import SEO from "~components/SEO";

const NotFound = ({ data, location }) => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  let i;

  // ---------------------------------------------------------------------------
  // render

  return (
    <>
      <SEO noindex />

      <Layout className="not-found-page relative">
        <section>
          <>Not Found</>
        </section>
      </Layout>
    </>
  );
};

export default NotFound;